<template>
  <div class="nodedetail-style">
    <div class="bigbox">
      <div class="box1">{{$t('text.t207')}}</div>
      <div class="box2">
        {{$t('text.t208')}}
      </div>
      <div class="box1">{{$t('text.t209')}}</div>
      <div class="box2">
        {{$t('text.t210')}}
      </div>
      <!-- <div class="box1">{{$t('text.t260')}}</div>
      <div class="box2">
        {{$t('text.t261')}}
      </div> -->
      <!-- <div class="box3">
        <img :src="`${$http.imgurl}${language==1?config.node_zh:config.node_en}`" alt="" class="b3-i1" />
      </div> -->
      <!-- <div class="box3">
        <img :src="`${$http.imgurl}${language==1?config.py_zh:config.py_en}`" alt="" class="b3-i1" />
      </div> -->
    </div>
  </div>
</template>
<script>
import {mapState} from 'vuex'
export default {
  components: {
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(["config","language"])
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="less">
.nodedetail-style {
  .bigbox {
    padding: 30px 0 0;
    .box1 {
      font-size: 12px;
      font-weight: bold;
      color: #323232;
      margin-bottom: 14px;
      text-align: center;
    }
    .box2 {
      width: 300px;
      margin: 0 auto 29px;
      font-size: 10px;
      font-weight: 500;
      color: #323232;
      line-height: 2;
    }
    .box3 {
      width: 350px;
      margin: 0 auto 50px;
      .b3-i1 {
        width: 100%;
        height: auto;
      }
    }
  }
}
</style>